import React from 'react'
import { CSSTransition } from 'react-transition-group'

import Overlay from './overlay'

const Popup = ({ children, visible }) => (
  <Overlay zindex="1000" visible={visible}>
    <CSSTransition in={visible} timeout={300} classNames="popup">
      <div className="popup">
        {children}
      </div>
    </CSSTransition>
  </Overlay>
)

const DSGVOPopup = ({ visible, closeHandler }) => (
  <Popup visible={visible}>
    <h2>Privacy Policy</h2>
    <div className="popup-content">
    <p>
      Personal data (usually referred to just as "data" below) will only be
      processed by us to the extent necessary and for the purpose of providing a
      functional and user-friendly website, including its contents, and the services
      offered there.
    </p>
    <p>
      Per Art. 4 No. 1 of Regulation (EU) 2016/679, i.e. the General Data Protection
      Regulation (hereinafter referred to as the "GDPR"), "processing" refers to any
      operation or set of operations such as collection, recording, organization,
      structuring, storage, adaptation, alteration, retrieval, consultation, use,
      disclosure by transmission, dissemination, or otherwise making available,
      alignment, or combination, restriction, erasure, or destruction performed on
      personal data, whether by automated means or not.
    </p>
    <p>
      The following privacy policy is intended to inform you in particular about the
      type, scope, purpose, duration, and legal basis for the processing of such
      data either under our own control or in conjunction with others. We also
      inform you below about the third-party components we use to optimize our
      website and improve the user experience which may result in said third parties
      also processing data they collect and control.
    </p>
    <p>Our privacy policy is structured as follows:</p>
    <p>
      I. Information about us as controllers of your data<br />II. The rights of
      users and data subjects<br />III. Information about the data processing
    </p>
    <h3>I. Information about us as controllers of your data</h3>
    <p>
      The party responsible for this website (the "controller") for purposes of data
      protection law is:
    </p>
    <p>
      Lucas Blochberger <br />
      Lilienthalgasse 1/6/11 <br />
      1030 Wien, AUT<br />
    </p>
    <p>
      Email: blckalpaca@gmail.com
    </p>
    <h3>II. The rights of users and data subjects</h3>
    <p>
      With regard to the data processing to be described in more detail below, users
      and data subjects have the right
    </p>
    <ul>
      <li>
        to confirmation of whether data concerning them is being processed,
        information about the data being processed, further information about the
        nature of the data processing, and copies of the data (cf. also Art. 15
        GDPR);
      </li>
      <li>
        to correct or complete incorrect or incomplete data (cf. also Art. 16 GDPR);
      </li>
      <li>
        to the immediate deletion of data concerning them (cf. also Art. 17 DSGVO),
        or, alternatively, if further processing is necessary as stipulated in Art.
        17 Para. 3 GDPR, to restrict said processing per Art. 18 GDPR;
      </li>
      <li>
        to receive copies of the data concerning them and/or provided by them and to
        have the same transmitted to other providers/controllers (cf. also Art. 20
        GDPR);
      </li>
      <li>
        to file complaints with the supervisory authority if they believe that data
        concerning them is being processed by the controller in breach of data
        protection provisions (see also Art. 77 GDPR).
      </li>
    </ul>
    <p>
      In addition, the controller is obliged to inform all recipients to whom it
      discloses data of any such corrections, deletions, or restrictions placed on
      processing the same per Art. 16, 17 Para. 1, 18 GDPR. However, this obligation
      does not apply if such notification is impossible or involves a
      disproportionate effort. Nevertheless, users have a right to information about
      these recipients.
    </p>
    <p>
      <strong>Likewise, under Art. 21 GDPR, users and data subjects have the right to
        object to the controller's future processing of their data pursuant to Art.
        6 Para. 1 lit. f GDPR. In particular, an objection to data processing for
        the purpose of direct advertising is permissible.</strong>
    </p>
    <h3>III. Information about the data processing</h3>
    <p>
      Your data processed when using our website will be deleted or blocked as soon
      as the purpose for its storage ceases to apply, provided the deletion of the
      same is not in breach of any statutory storage obligations or unless otherwise
      stipulated below.
    </p>
    <h4>Contact</h4>
    <p>
      If you contact us via email or the contact form, the data you provide will be
      used for the purpose of processing your request. We must have this data in
      order to process and answer your inquiry; otherwise we will not be able to
      answer it in full or at all.
    </p>
    <p>The legal basis for this data processing is Art. 6 Para. 1 lit. b GDPR.</p>
    <p>
      Your data will be deleted once we have fully answered your inquiry and there
      is no further legal obligation to store your data, such as if an order or
      contract resulted therefrom.
    </p>
    <h4>Social media links via graphics</h4>
    <p>
      We also integrate the following social media sites into our website. The
      integration takes place via a linked graphic of the respective site. The use
      of these graphics stored on our own servers prevents the automatic connection
      to the servers of these networks for their display. Only by clicking on the
      corresponding graphic will you be forwarded to the service of the respective
      social network.
    </p>
    <p>
      Once you click, that network may record information about you and your visit
      to our site. It cannot be ruled out that such data will be processed in the
      United States.
    </p>
    <p>
      Initially, this data includes such things as your IP address, the date and
      time of your visit, and the page visited. If you are logged into your user
      account on that network, however, the network operator might assign the
      information collected about your visit to our site to your personal account.
      If you interact by clicking Like, Share, etc., this information can be stored
      your personal user account and possibly posted on the respective network. To
      prevent this, you need to log out of your social media account before clicking
      on the graphic. The various social media networks also offer settings that you
      can configure accordingly.
    </p>
    <p>
      The following social networks are integrated into our site by linked graphics:
    </p>
    <h4>Facebook</h4>
    <p>
      Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland, a
      subsidiary of Facebook Inc., 1601 S. California Ave., Palo Alto, CA 94304,
      USA.
    </p>
    <p>
      Privacy Policy: <a href="https://www.facebook.com/policy.php" target="_blank" rel="noreferrer">https://www.facebook.com/policy.php</a>
    </p>
    <h4>Instagram</h4>
    <p>
      Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland, a
      subsidiary of Facebook Inc., 1601 S. California Ave., Palo Alto, CA 94304,
      USA.
    </p>
    <p>
      Privacy Policy: <a href="https://help.instagram.com/519522125107875" target="_blank" rel="noreferrer"> https://help.instagram.com/519522125107875</a>
    </p>
    <p>
      <a href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html" target="_blank" rel="noreferrer">Model Data Protection Statement</a> for <a href="https://www.ratgeberrecht.eu/" target="_blank" rel="noreferrer">Anwaltskanzlei Weiß &amp; Partner</a>
    </p>
    </div>
    <div className="popup-button-row">
      <button className="button" onClick={closeHandler}>Close</button>
    </div>
  </Popup>
)

export default Popup
export { DSGVOPopup }

