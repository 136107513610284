import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { DSGVOPopup } from "./popup"
import { CSSTransition } from "react-transition-group"

const Form = props => {
  const data = useStaticQuery(graphql`
    query FormPathQuery {
      site {
        siteMetadata {
          formPath
        }
      }
    }
  `)

  const FORM_INITIAL = "FORM_INITIAL"
  const FORM_SENT = "FORM_SENT"
  const [displayMode, setDisplayMode] = useState(FORM_INITIAL)
  const [contactName, setContactName] = useState("")
  const [popupVisible, setPopupVisible] = useState(false)

  const handleFormSubmit = e => {
    e.preventDefault()

    let form = document.querySelector("form.contact-form")
    let formFields = form.querySelectorAll("input")
    var valid = true
    formFields.forEach(field => {
      if (field.hidden) return

      if (field.id === "name" && field.value) {
        setContactName(field.value)
        return
      } else if (field.id === "email" && field.value && field.validity) {
        return
      } else if (field.id === "subject" && field.value) {
        return
      } else if (field.id === "dsgvo" && field.checked) {
        return
      }
      valid = false
      field.classList.add("invalid")
    })

    if (!valid) return

    let formData = new FormData(form)
    let asString = new URLSearchParams(formData).toString()
    fetch(form.getAttribute("action"), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
      },
      mode: "no-cors",
      method: "POST",
      body: asString,
    }).then(() => {
      setDisplayMode(FORM_SENT)
    })
  }

  const handleClose = () => {
    props.closeHandler()
    setTimeout(() => setDisplayMode(FORM_INITIAL), 300)
  }

  const toggleGDPRpopup = () => {
    window.location.hash = "contact"
    setPopupVisible(!popupVisible)
  }

  return (
    <>
      <div className="content">
        <CSSTransition
          classNames="contact-form"
          in={displayMode === FORM_INITIAL}
          timeout={500}
        >
          <form
            action={data.site.siteMetadata.formPath}
            method="post"
            className="contact-form"
          >
            <h2>{props.title}</h2>
            <div
              className="contact-form-block contact-name"
              style={{ "--animation-order": 1 }}
            >
              <label className="contact-label" htmlFor="name">
                Name
              </label>
              <div className="input-holder">
                <input type="text" name="name" id="name" />
                <span className="contact-hint">Please tell us your name.</span>
              </div>
            </div>
            <div
              className="contact-form-block contact-email"
              style={{ "--animation-order": 2 }}
            >
              <label className="contact-label" htmlFor="email">
                Email
              </label>
              <div className="input-holder">
                <input type="email" name="email" id="email" />
                <span className="contact-hint">
                  Please provide an email address where we can reach you.
                </span>
              </div>
            </div>
            <div
              className="contact-form-block contact-subject"
              style={{ "--animation-order": 3 }}
            >
              <label className="contact-label" htmlFor="subject">
                Subject
              </label>
              <div className="input-holder">
                <input type="text" name="subject" id="subject" />
                <span className="contact-hint">Just some short note.</span>
              </div>
            </div>
            <div
              className="contact-form-block contact-message"
              style={{ "--animation-order": 4 }}
            >
              <label className="contact-label" htmlFor="message">
                Message
              </label>
              <div className="input-holder">
                <textarea name="message" id="message"></textarea>
              </div>
            </div>
            <div
              className="contact-form-block contact-send"
              style={{ "--animation-order": 5 }}
            >
              <div className="input-holder">
                <label htmlFor="dsgvo" className="contact-label">
                  <input
                    type="checkbox"
                    name="dsgvo"
                    id="dsgvo"
                    title="Accept GDPR"
                    required
                  />
                  <span className="contact-checkbox"></span>I accept the{" "}
                  <a href="#dsgvo" onClick={() => toggleGDPRpopup()}>
                    privacy policy.
                  </a>
                </label>
                <button
                  className="send-message-button button"
                  title="Send message"
                  data-action="submit"
                  onClick={e => handleFormSubmit(e)}
                >
                  Send message
                </button>
              </div>
            </div>
            <input name="form_tools_form_id" value="1" readOnly hidden />
            <div className="contact-form-block-hidden">
              <input type="text" name="title" id="title" hidden />
            </div>
          </form>
        </CSSTransition>

        <CSSTransition
          classNames="form-thanks"
          in={displayMode === FORM_SENT}
          timeout={1000}
        >
          <div className="content form-thanks" style={{ textAlign: "center" }}>
            <h2>Thanks, {contactName}!</h2>
            <p>We'll get back to you soon.</p>
            <button
              className="button"
              onClick={handleClose}
              style={{ height: 44 }}
            >
              Close
            </button>
          </div>
        </CSSTransition>
      </div>
      <DSGVOPopup
        visible={popupVisible}
        closeHandler={() => toggleGDPRpopup()}
      />
    </>
  )
}

export default Form
