/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import Overlay from "./overlay"
import Form from "./form"
import "../style/layout.css"
import "../style/animation.css"

class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      contactShown: false,
      imprintShown: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (typeof window === "undefined") return state

    if (props.location.hash === "") {
      return { contactShown: false, imprintShown: false }
    }
    if (props.location.hash === "#dsgvo") {
      return state
    }
    return {
      contactShown: props.location.hash === "#contact",
      imprintShown: props.location.hash === "#impressum",
    }
  }

  toggleContactShown = e => {
    e.preventDefault()
    window.location.hash = this.state.contactShown ? "" : "contact"
    var newDisplayState = {}
    if (this.state.imprintShown) {
      newDisplayState = { imprintShown: false }
    }
    newDisplayState = {
      ...newDisplayState,
      contactShown: !this.state.contactShown,
    }
    this.setState({ ...this.state, ...newDisplayState })
  }

  toggleImprintShown = e => {
    e.preventDefault()
    window.location.hash = this.state.imprintShown ? "" : "impressum"
    var newDisplayState = {}
    if (this.state.contactShown) {
      newDisplayState = { contactShown: false }
    }
    newDisplayState = {
      ...newDisplayState,
      imprintShown: !this.state.imprintShown,
    }
    this.setState({ ...this.state, ...newDisplayState })
  }

  render() {
    return (
      <>
        <StaticQuery
          query={graphql`
            query SiteTitleQuery {
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={data => (
            <Header
              siteTitle={data.site.siteMetadata?.title || `Title`}
              contactHandler={this.toggleContactShown}
              state={this.state}
            />
          )}
        />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0 1.0875rem 1.45rem`,
          }}
        >
          <main>{this.props.children}</main>
        </div>
        <Overlay visible={this.state.contactShown}>
          <Form
            title="Drop us a message!"
            closeHandler={e => this.toggleContactShown}
          />
        </Overlay>
        <Overlay visible={this.state.imprintShown}>
          <div className="content imprint">
            <h2>Imprint</h2>
            <p>
              Lucas Blochberger <br />
              Lilienthalgasse 1/6/11 <br />
              1030 Wien, AUT
              <br />
              ATU 7418&nbsp;4045
            </p>
            <p>Email: blckalpaca@gmail.com</p>
          </div>
        </Overlay>
        <Footer imprintHandler={this.toggleImprintShown} />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
