import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { IoVolumeHighOutline, IoVolumeMuteOutline } from "react-icons/io5"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      muted: true,
    }
  }

  toggleMute = () => {
    let videos = document.querySelectorAll("aside#video-bg video")
    videos.forEach(video => (video.muted = !video.muted))
    this.setState({ ...this.state, muted: !this.state.muted })
  }

  render() {
    let { siteTitle, contactHandler } = this.props
    return (
      <header
        style={{
          marginBottom: `1.45rem`,
        }}
      >
        <div
          style={{
            margin: `0 auto`,
            // maxWidth: 960,
            padding: `1.45rem 1.0875rem`,
          }}
        >
          <h1 style={{ margin: 0 }}>
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              {siteTitle}
            </Link>
          </h1>
          <div>
            <nav>
              <li>
                <a href="#contact" onClick={contactHandler} className="button">
                  {this.props.state.contactShown ? `Close` : `Contact`}
                </a>
              </li>
            </nav>
            <button id="mute" onClick={() => this.toggleMute()}>
              {this.state.muted ? (
                <IoVolumeMuteOutline size="24" />
              ) : (
                <IoVolumeHighOutline size="24" />
              )}
            </button>
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
