import React from "react"
import { CSSTransition } from "react-transition-group"

const Overlay = ({ children, visible, backgroundColor, zindex }) => (
  <CSSTransition
    in={visible}
    appear={visible}
    timeout={300}
    classNames="overlay"
  >
    <div className="overlay">
      {children}
      <div
        className="overlay-bg"
        style={{ backgroundColor: backgroundColor, zIndex: zindex }}
      ></div>
    </div>
  </CSSTransition>
)

export default Overlay
