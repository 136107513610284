import React from 'react'

import {SiFacebook, SiInstagram} from "react-icons/si"

const Footer = ({ imprintHandler }) => (
  <footer>
    <p>
      © {new Date().getFullYear()}, Blck Alpaca  |  <a href="#impressum" onClick={imprintHandler}>Imprint</a><br />
      Built by{` `}<a href="https://rivic.at" target="_blank" rel="noreferrer">RivicVisuals.</a>
    </p>
    <a href="https://www.facebook.com/blckalpaca/" target="_blank" rel="noreferrer" className="button">
      <SiFacebook size="24" />
    </a>
    <a href="https://www.instagram.com/blckalpaca/" target="_blank" rel="noreferrer" className="button">
      <SiInstagram size="24" />
    </a>
  </footer>
)

export default Footer